// import {useAlert} from './ginger.alert';
// import {getResult} from './ginger.utils';
//
// const {showAlert} = useAlert();
//
export const validateParams = (validateKeys, target, isShowAll) => {
    const keys = Object.keys(validateKeys);
    const msgs = [];
    keys.forEach(key => {
        if (!target[key]) {
            msgs.push(validateKeys[key]);
        }
    });

    if (msgs.length > 0) {
        alert(isShowAll ? msgs.join('\n') : msgs[0]);
    }
    return msgs.length === 0;
};
//
// export const showErrorAlert = () => {
//     showAlert({
//         text: '오류가 발생했습니다.<br>잠시 후 다시 시도해주세요.',
//     });
// };
//
// export const showResultAlert = res => {
//     const result = getResult(res);
//     showAlert({
//         text: result.message,
//     });
// };
//
// const emailRule =
//     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// export const checkEmail = email => {
//     return emailRule.test(email);
// };
//
// const regRule = /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{8,16}$/;
// export const checkPassword = passwd => {
//     // 숫자 + 문자 8~13자리
//     return regRule.test(passwd);
// };
//
// export const fastqRex = /(?<sample_id>^[a-zA-Z0-9-]+)(_.*)?_R?[12]\.(fastq(\.gz)?|fq(\.gz)?)/gm;
// // export const fastqRexIns = new RegExp(fastqRex);
// // export const fastqRex = /(?<sample_id>^[a-zA-Z0-9-]+)(_.*)?_R?[12]\.(fastq(\.gz)?|fq(\.gz)?)/g;
// export const bamRex = /(^[a-zA-Z0-9-]+)(_.*)?\.(bam)/gm;
// export const vcfRex = /(^[a-zA-Z0-9-]+)(_.*)?\.(vcf)/gm;

const IMAGE_MIMES = [
    {type: 'image/bmp', ext: 'bmp'},
    {type: 'image/cis-cod', ext: 'cod'},
    {type: 'image/gif', ext: 'gif'},
    {type: 'image/ief', ext: 'ief'},
    {type: 'image/jpeg', ext: 'jpe'},
    {type: 'image/jpeg', ext: 'jpeg'},
    {type: 'image/jpeg', ext: 'jpg'},
    {type: 'image/png', ext: 'png'},
    {type: 'image/pipeg', ext: 'jfif'},
    {type: 'image/svg+xml', ext: 'svg'},
    {type: 'image/tiff', ext: 'tif'},
    {type: 'image/tiff', ext: 'tiff'},
    {type: 'image/x-cmu-raster', ext: 'ras'},
    {type: 'image/x-cmx', ext: 'cmx'},
    {type: 'image/x-icon', ext: 'ico'},
    {type: 'image/x-portable-anymap', ext: 'pnm'},
    {type: 'image/x-portable-bitmap', ext: 'pbm'},
    {type: 'image/x-portable-graymap', ext: 'pgm'},
    {type: 'image/x-portable-pixmap', ext: 'ppm'},
    {type: 'image/x-rgb', ext: 'rgb'},
];

// export const getMimeTypeByExtension = ext => {
//     if (ext && ext.length > 0) {
//         IMAGE_MIMES.filter(x => {x.ext === ext});
//     }
// };

export const validateImageMineType = type => {
    return IMAGE_MIMES.filter(x => x.type === type).length > 0;
};

export const isSvg = url => {
    return url && url.indexOf('.svg') > -1;
};

export const checkKor = str => {
    const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
    return regExp.test(str);
};
