<template>
    <main class="swcluster-main" id="swcluster-datahub">
        <!-- page-header -->
        <div class="page-header">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="card-list-container card-image-container">
                    <div class="card-component">
                        <ul class="card-list" data-column="3" data-ratio="1x1">
                            <!-- card-item -->
                            <li v-for="(item, index) in items" class="card-item swcc-cursor-pointer" :key="index" @click="goView(item.bno)">
                                <div class="card">
                                    <div class="card-image">
                                        <img v-if="item.boardThumbnail" :src="item.boardThumbnail" alt="" />
                                        <label v-else class="form-file-label form-file-thumbnail">
                                            <i class="icon-thumbnail-placeholder"></i>
                                        </label>
                                    </div>
                                    <!--                                    첨부파일이 있을시 뱃지 표시한다. -->
                                    <!--                                    <div class="is-databadge">Data</div>-->

                                    <div class="card-body">
                                        <h4 class="card-title title-lg">{{ item.boardNm }}</h4>
                                        <p class="card-text">{{ item.boardSubNm }}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <CommonPagination2 :paging="paging" :page-func="pageFunc" />
                </div>
            </div>
            <!-- //page-contents -->
        </div>

        <div v-if="session.name !== null && session.manager" class="page-bottom bottom-fixed" style="bottom: 16px">
            <div class="bottom-board-upload bottom-component">
                <button class="btn btn-primary" @click="goEdit(name, 'bno', 0)"><span class="text">글작성 </span></button>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import CommonPagination2 from '@/components/common/CommonPagination2';
import {computed, reactive, ref, watch} from 'vue';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {useRoute, useRouter} from 'vue-router';
import {getBoardList} from '@/assets/js/modules/board/module';
import {domainCode} from '@/assets/js/domain.code';
import {getItems, getTotalCount, lengthCheck} from '@/assets/js/common.utils';
import {getValueByQuery} from '@/assets/js/route.utils';
import {goEdit, pageFunc} from '@/assets/js/modules/common/module';
import {isSvg} from '@/assets/js/common.validate';

export default {
    name: 'Board',
    components: {CommonPagination2, CommonBreadcrumb},
    setup() {
        const session = computed(getAuthSession);
        const route = useRoute();
        const router = useRouter();

        const items = ref([]);

        const paging = reactive({
            pageNo: 1,
            pageSize: 9,
            totalCount: 0,
        });

        const divisions = reactive({
            name: computed(() => route.name),
            key: computed(() => route.name.toLowerCase()),
            viewName: computed(() => `${route.name}View`),
            title: computed(() => {
                if (route.name.includes('Consulting')) {
                    return 'AI ・ 데이터 컨설팅';
                } else if (route.name.includes('Conversion')) {
                    return 'SW 융합사례';
                } else if (route.name.includes('Visualization')) {
                    return '시각화 분석사례';
                } else if (route.name.includes('Analytics')) {
                    return '애널리틱 분석사례';
                }
                return '';
            }),
            boardTypeDcd: computed(() => {
                if (route.name.includes('Consulting')) {
                    return domainCode.BOARD_TYPE_CONSULTING;
                } else if (route.name.includes('Conversion')) {
                    return domainCode.BOARD_TYPE_CONVERSION;
                } else if (route.name.includes('Visualization')) {
                    return domainCode.BOARD_TYPE_VISUALIZATION;
                } else if (route.name.includes('Analytics')) {
                    return domainCode.BOARD_TYPE_ANALYTICS;
                }
                return null;
            }),
            messages: computed(() => {
                if (route.name.includes('Consulting')) {
                    return {
                        confirm: 'AI ・ 데이터 컨설팅을',
                        complete: 'AI ・ 데이터 컨설팅이',
                    };
                } else if (route.name.includes('Conversion')) {
                    return {
                        confirm: 'SW 융합사례를',
                        complete: 'SW 융합사례가',
                    };
                } else if (route.name.includes('Visualization')) {
                    return {
                        confirm: '시각화 분석사례를',
                        complete: '시각화 분석사례가',
                    };
                } else if (route.name.includes('Analytics')) {
                    return {
                        confirm: '애널리틱 분석사례를',
                        complete: '애널리틱 분석사례가',
                    };
                }
                return {};
            }),
        });

        const getCommonBoardList = () => {
            getBoardList({boardTypeDcd: divisions.boardTypeDcd, ...paging}).then(res => {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                    paging.totalCount = getTotalCount(res);
                }
            });
        };

        const goView = bno => {
            router.push({name: divisions.viewName, params: {bno}});
        };

        watch(
            () => route.query,
            () => {
                if (route.name === divisions.name) {
                    paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                    items.value = [];
                    paging.totalCount = 0;
                    getCommonBoardList();
                }
            },
        );

        getCommonBoardList();

        return {
            name: route.name,
            session,
            items,
            paging,
            goView,
            goEdit,
            isSvg,
            pageFunc,
        };
    },
};
</script>
